<template>
  <div class="wrapper static-article">
    <row>
      <column
        xs="12"
        md="2">
      </column>
      <column
        xs="12"
        md="8">
        <h1 class="ff-flama fs-xxl fw-light tt-uppercase mb-5">
          Advertise
        </h1>
        <div class="article-body clearfix mb-3">
          <p>
            Advertise with IntraFish, the world’s leading aquaculture and fisheries news source.
          </p>
          <p>
            We cover the entire seafood value chain and deliver an ever-growing readership of the most influential and engaged seafood professionals around the globe.
          </p>
          <p>
            We provide a variety of options for advertisers across print and digital media – video, banners and content marketing packages. With a range of leading industry titles and media channels, <strong>IntraFish</strong> offers the opportunity to build a campaign to best suit your needs.
          </p>
          <p>
            Contact <strong>IntraFish</strong> today to learn how we can help you reach decision makers in the seafood industry.
          </p>

          <p>
            <strong>Global publications</strong><br>
            <a href="https://advertise.intrafish.com/">IntraFish advertising profile and media guide</a><br>
            <a href="https://cdn2.hubspot.net/hubfs/1545457/IntraFish%20advertise%20page/IFM-2019.10%20-IFM%20Content%20Mark%20PDF_v3.pdf">IntraFish.com content marketing guide</a><br>
            <a href="https://global.nhst.tech/intafish.com/IFM-2019.09%20-%20IFM%20Calendar_v18.pdf">IntraFish.com events marketing calendar</a>
          </p>
          <p>
            <strong>Norwegian publications</strong><br>
            <a href="https://ocast.com/no/fiskeribladet-intrafish-tekfisk/">IntraFish.no Media kit</a> (Norwegian)<br>
            <a href="https://ocast.com/no/fiskeribladet-intrafish-tekfisk/">IntraFish.no Media kit</a> (English)<br>
            <a href="https://ocast.com/no/fiskeribladet-intrafish-tekfisk/">Fiskeribladet Media kit</a> (Norwegian)<br>
            <a href="https://ocast.com/no/fiskeribladet-intrafish-tekfisk/">Tekfisk Media kit</a> (Norwegian)
          </p>
          <p>
            For further information please contact <a href="mailto:sales@intrafish.com">sales@intrafish.com</a>
          </p>
        </div>
      </column>
    </row>
  </div>
</template>

<script>
import { Page } from 'global-components';
export default {
  name: 'advertise-page',
  extends: Page
};
</script>
